import {GET_CUSTOMERS_CREDIT_CARDS} from '../Queries/customer';

class CreditCardLib {

    constructor(parent){
        this.parent = parent;
    }
    
    updateAfterDeleteCustomerCreditCard(cache, response,customerListVariables){

        var resp = response.data.deleteCustomersCreditCard;

        const { allCustomerCreditCards } = cache.readQuery({ query: GET_CUSTOMERS_CREDIT_CARDS,variables:customerListVariables });
        var arr = [...allCustomerCreditCards];

        
        arr.forEach((item,index) => {
            if(item.token == resp){
                arr = [...arr.slice(0,index),...arr.slice(index + 1)]
            }
        });
    
        cache.writeQuery({ 
            query:GET_CUSTOMERS_CREDIT_CARDS,
            variables:customerListVariables,
            data:{
                allCustomerCreditCards: [...arr]
            } 
        });

    }

}

export default CreditCardLib;