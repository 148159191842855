/**
 * Třía která obsahuje veškeré pomocné funkce, pro práci s přidáváním a editací stavů objednávek
 */

import {GET_ARTICLE,GET_ARTICLES,SEACRH_ARTICLES,GET_ARTICLE_RECOMMENDED, GET_ARTICLE_MARKETINGS, GET_ARTICLE_SERVICES,GET_ARTICLE_FORMS,PUBLISH_ARTICLE,UNPUBLISH_ARTICLE} from '../Queries/article';
import Notification from '../../../Library/notification';
import {ARTICLE_IMAGE_URL,SERVER_URL} from '../../../Config/index';
const moment             = require("moment");

class Article {

    constructor(parent){
        this.parent = parent;
    }

    
    /**
     * Přidání/Editace
     * @param {*} addArticle funkce z apollo, pomocí které se posílají data na server
     */

    addArticle = async (addArticle,callback = null) => {

        const data = this.parent.state.formData;
        let notify = new Notification();   
        
        if((data.photo != "" && !this.parent.props.selectedArticleID) || (this.parent.props.selectedArticleID && this.parent.props.selectedArticleID != 0)){ 
            if(data.linkIDs && data.linkIDs.length > 0){
                        
                let langs = [];
                for(const lang of data.langs){

                    const publishDate = lang.publishDate ? moment(lang.publishDate).format("YYYY-MM-DD") : null;
                    langs.push({
                        title:          lang.title,
                        publishDate,
                        perex:          lang.perex,
                        marketingText:  lang.marketingText,
                        text:           lang.text,
                        metaTitle:      lang.metaTitle,
                        metaKeywords:   lang.metaKeywords,
                        metaDescription:lang.metaDescription,
                        lang:           lang.lang
                    });
                }
                

                addArticle({
                    variables:{
                        articleID:data.articleID,
                        linkIDs:data.linkIDs,
                        withoutMainImage:data.withoutMainImage,
                        generateRandomRecommended:data.generateRandomRecommended,
                        alone:data.alone,
                        photo:data.photo,
                        langs
                    }
                }).then(responseData => {
                    if(callback){
                        callback(true,responseData);
                    }
                }, errorData =>{
                    if(callback){
                        callback(false,errorData);
                    }
                });
            } else{
                notify.setNotification(null,'Článek musí být přiřazen k nějaké kategorii.',false,true,this.parent.props.client);
            }
        }else{
            notify.setNotification(null,'Nevybrali jste fotku.',false,true,this.parent.props.client);
        }
               
    } 

    unpublishArticle = async (articleID,lang,variables) => {
        const notify = new Notification(); 

        this.parent.props.client.mutate({ 
            mutation: UNPUBLISH_ARTICLE,
            variables:{
                articleID,
                lang,
            },
        }).then((result) => {

            const { allArticles } = this.parent.props.client.readQuery({ query: GET_ARTICLES,variables:variables });

            var arr = [...allArticles];
        
            arr.forEach((item,index) => {
                
                if(item.articleID == articleID){

                    item.langPublished.forEach((it,ind) => {
                
                        if(it.lang == lang){

                            var arr2 = [...arr[index].langPublished];
                            arr2[ind] = {...arr2[ind],published:0};
                            arr[index] = {...arr[index],langPublished:arr2}
                        }
    
                    });

                }

            });

            this.parent.props.client.writeQuery({ 
                query:GET_ARTICLES,
                variables:variables,
                data:{
                    allArticles: arr
                } 
            });

            this.parent.props.openCloseModal(false);
            
            notify.setNotification(null,'Článek úspěšně odpublikován',true,false,this.parent.props.client);

        }).catch((err) => {
            
            notify.setNotification(null,err,false,true,this.parent.props.client);
            
        }) 
    }

    publishArticle = async (articleID,lang,variables) => {
 
        const notify = new Notification();    

        this.parent.props.client.mutate({ 
            mutation: PUBLISH_ARTICLE,
            variables:{
                articleID,
                lang,
                sendNewsletter: this.parent.state.formData.sendNewsletter
            },
        }).then((result) => {

            const { allArticles } = this.parent.props.client.readQuery({ query: GET_ARTICLES,variables:variables });

            var arr = [...allArticles];
        
            arr.forEach((item,index) => {
                
                if(item.articleID == articleID){

                    item.langPublished.forEach((it,ind) => {
                
                        if(it.lang == lang){

                            var arr2 = [...arr[index].langPublished];
                            arr2[ind] = {...arr2[ind],published:1};
                            arr[index] = {...arr[index],langPublished:arr2}
                        }
    
                    });

                }

            });

            this.parent.props.client.writeQuery({ 
                query:GET_ARTICLES,
                variables:variables,
                data:{
                    allArticles: arr
                } 
            });
            
            notify.setNotification(null,'Článek úspěšně publikován',true,false,this.parent.props.client);

        }).catch((err) => {
            
            notify.setNotification(null,err,false,true,this.parent.props.client);
            
        })  

    }
    
    /**
     * při inicializaci vložení nebo úpravy se vytáhnou potřebná data
     */

    async getData(){

        var articleID = 0;
        if(this.parent.props.selectedArticleID)articleID = this.parent.props.selectedArticleID;
        
        var data = await this.parent.props.client.query({ 
            query: GET_ARTICLE,
            errorPolicy:"all",
            variables:{articleID:articleID}, 
            fetchPolicy: 'network-only'
        });
        
        
        if(data.errors){
            this.parent.setState({apolloError:data.errors[0].message});
        }
        
        //kdyz upravujeme zaznam
        if(data.data){                  
            if(data.data.articleWithLangs){    

                for(const lang of data.data.articleWithLangs.langs){
                    lang.publishDate = lang.publishDate ? moment(lang.publishDate).toDate() : null;
                }
                
                let langs = this.completeLangsArray(data.data.articleWithLangs.langs,data.data.allLanguageMutations);    
                
                var mainPhoto = "";
                if(data.data.articleWithLangs.photo) mainPhoto = SERVER_URL + "/" + ARTICLE_IMAGE_URL + "/stredni_" + data.data.articleWithLangs.photo;
                
                this.parent.setState({
                    apolloError:"",
                    selectedArticleID:articleID,
                    mainPhoto,
                    allLanguageMutations: data.data.allLanguageMutations,
                    allServices: data.data.allServices,
                    allForms: data.data.allForms,
                    formData:{...this.parent.state.formData,
                        articleID:     data.data.articleWithLangs.articleID,
                        linkIDs:     data.data.articleWithLangs.linkIDs,
                        generateRandomRecommended:data.data.articleWithLangs.generateRandomRecommended,
                        alone: data.data.articleWithLangs.alone,
                        withoutMainImage: data.data.articleWithLangs.withoutMainImage,
                        langs:langs
                    }
                });
            }else{
                this.parent.setState({ apolloError:""});
            }
        }

        //kdyz pridavame zaznam
        if(articleID == 0){
            let langs = this.completeLangsArray([],data.data.allLanguageMutations);     
            this.parent.setState({
                selectedArticleID: articleID,
                allLanguageMutations: data.data.allLanguageMutations,
                allServices: data.data.allServices,
                allForms: data.data.allForms,
                formData:{...this.parent.state.formData,
                    generateRandomRecommended:true,
                    langs:langs
                }
            });
        }

    }

    completeLangsArray = (langsFromDb,allActiveLanguages) => {
        let langs = langsFromDb;
        for(const l of allActiveLanguages){
            let langSet = false;
            for(const lang of langs){
                if(l.suffix == lang.lang){
                    langSet = true;
                    break;
                }
            }

            if(!langSet){
                langs.push({
                    title:"",                    
                    publishDate:  moment().subtract(1,'day').toDate(),
                    perex:"",
                    marketingText:"",
                    text:"",
                    metaTitle:"",
                    metaKeywords:"",
                    metaDescription:"",
                    lang:l.suffix
                });
            }
        }
        return langs;
    }

    /**
     * Při vložení nebo úpravě zákazníka dojde k update cache a vykreslí se opět seznam veškerých zákazníků
     * @param {Object} cache apollo cahe v které jsou oložena data
     * @param {Object} response položka, která byla vložena nebo upravována
     * @param {Number} selectedID id položky
     * @param {Object} variables parametry posílané do query
     */

    updateList(cache,response,selectedID,variables){

        const { allArticles } = cache.readQuery({ query: GET_ARTICLES,variables:variables });
        
        if(!(selectedID && selectedID != 0)){

            const arr = [response.data.addEditArticle,...allArticles]   ;
            
            cache.writeQuery({ 
                query:GET_ARTICLES,
                variables:variables,
                data:{
                    allArticles: arr
                } 
            });

        }else{

            var arr = [...allArticles];
            
            arr.forEach((item,index) => {
                
                if(item.articleID == response.data.addEditArticle.articleID){
                    arr[index].articleID       = response.data.addEditArticle.articleID;
                    arr[index].title           = response.data.addEditArticle.title;
                    arr[index].niceTitle       = response.data.addEditArticle.niceTitle;
                    arr[index].lang            = response.data.addEditArticle.lang;
                    arr[index].langPublished   = response.data.addEditArticle.langPublished;

                    if(response.data.addEditArticle.photo){
                        arr[index].photo = response.data.addEditArticle.photo;
                    }
                }

            });
            cache.writeQuery({ 
                query:GET_ARTICLES,
                variables:variables,
                data:{
                    allArticles: arr
                } 
            });
        }
    }

    /**
     * Update seznamu souvisejících prioduktů
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po přidání souvisejících produktu
     * @param {*} articleID ID článku, kterému se doporučený článek vkládá
     */

    updateAfterAddRRecommendedArticle(cache, response,articleID){

        const { recommendedArticles } = cache.readQuery({ query: GET_ARTICLE_RECOMMENDED,variables:{articleID,lang:"cz"} });

        cache.writeQuery({ 
            query:GET_ARTICLE_RECOMMENDED,
            variables:{articleID,lang:"cz"},
            data:{
                recommendedArticles: [response.data.addRecommendedArticle,...recommendedArticles]
            } 
        });

        
    }
    /**
     * Update seznamu souvisejících prioduktů
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po přidání souvisejících produktu
     * @param {*} articleID ID článku, kterému se doporučený článek vkládá
     */

    updateAfterAddArticleMarketing(cache, response,articleID){

        const { articleMarketings } = cache.readQuery({ query: GET_ARTICLE_MARKETINGS,variables:{articleID,lang:"cz"} });

        cache.writeQuery({ 
            query:GET_ARTICLE_MARKETINGS,
            variables:{articleID,lang:"cz"},
            data:{
                articleMarketings: [response.data.addArticleMarketing,...articleMarketings]
            } 
        });

        
    }
    /**
     * Update seznamu souvisejících služeb
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po přidání souvisejících produktu
     * @param {*} articleID ID článku, kterému se doporučený článek vkládá
     */

    updateAfterAddArticleService(cache, response,articleID){

        const { articleServices } = cache.readQuery({ query: GET_ARTICLE_SERVICES,variables:{articleID,lang:"cz"} });

        cache.writeQuery({ 
            query:GET_ARTICLE_SERVICES,
            variables:{articleID,lang:"cz"},
            data:{
                articleServices: [response.data.addArticleService,...articleServices]
            } 
        });

        
    }
    
    /**
     * Update seznamu doporučených článků po smazání
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání potahu
     * @param {Number} articleID ID článku
     */
    
    updateAfterDeleteRecommendedArticle(cache, response,articleID){

        var resp = response.data.deleteRecommendedArticles.split(",");

        const { recommendedArticles } = cache.readQuery({ query: GET_ARTICLE_RECOMMENDED,variables:{articleID,lang:"cz"} });
        var arr = [...recommendedArticles];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.articleRecommendedID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        })
    
        cache.writeQuery({ 
            query:GET_ARTICLE_RECOMMENDED,
            variables:{articleID,lang:"cz"},
            data:{
                recommendedArticles: [...arr]
            } 
        });

    }

    /**
     * Update seznamu doporučených článků po smazání
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání potahu
     * @param {Number} articleID ID článku
     */
    
    updateAfterDeleteArticleMarketing(cache, response,articleID){

        var resp = response.data.deleteArticleMarketings.split(",");

        const { articleMarketings } = cache.readQuery({ query: GET_ARTICLE_MARKETINGS,variables:{articleID,lang:"cz"} });
        var arr = [...articleMarketings];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.articleMarketingID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        })
    
        cache.writeQuery({ 
            query:GET_ARTICLE_MARKETINGS,
            variables:{articleID,lang:"cz"},
            data:{
                articleMarketings: [...arr]
            } 
        });

    }

    /**
     * Update seznamu doporučených článků po smazání
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání potahu
     * @param {Number} articleID ID článku
     */
    
    updateAfterDeleteArticleService(cache, response,articleID){

        var resp = response.data.deleteArticleServices.split(",");

        const { articleServices } = cache.readQuery({ query: GET_ARTICLE_SERVICES,variables:{articleID,lang:"cz"} });
        var arr = [...articleServices];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.articleServiceID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        })
    
        cache.writeQuery({ 
            query:GET_ARTICLE_SERVICES,
            variables:{articleID,lang:"cz"},
            data:{
                articleServices: [...arr]
            } 
        });

    }

    /**
     * Vyhledávání článku
     * @param {*} text Vzhledané
     */
    async seachArticle(text){

        var data = await this.parent.props.client.query({ 
            query: SEACRH_ARTICLES,
            variables:{
                text,
                lang:"cz"
            }, 
            fetchPolicy: 'network-only'
        });

        this.parent.setState({
            showSearchedArticles:true,
            searchedArticles:data.data.searchArticles
        })

    }


    /**
     * Otevře obrázke, zpracuje a zobrazí jeho náhled
     * @param {*} e 
     */

    openImage(e){
        var photo = e.target.files[0];        
        var reader = new FileReader();
        reader.onload = () => {
            var dataURL = reader.result;
            this.parent.setState({
                mainPhoto:dataURL,
                formData:{...this.parent.state.formData,photo:photo}
            })
        };
        reader.readAsDataURL(photo);
    }

    /**
     * 
     * @param {*} cache apollo cache, kde jsou uložena data
     * @param {*} response hodnota, která se vrátila po smazání objednávky 
     */

    updateAfterDelete(cache, response,variables){

        var resp = response.data.deleteArticles.split(",");

        const { allArticles } = cache.readQuery({ query: GET_ARTICLES ,variables});
        var arr = [...allArticles];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.articleID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        }) 
    
        cache.writeQuery({ 
            query:GET_ARTICLES,
            variables,
            data:{
                allArticles: [...arr]
            } 
        });

    }

    /**
     * funkce ukládá data, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formHandle(e){
	  
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        this.parent.setState({formData:{...this.parent.state.formData,[n]: v}});
              
    }
    /**
     * funkce ukládá data po zmene jazykove mutace ve formulari, která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    
    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře
     * @param {*} e ukazatel na element
     */
    
    formLangHandle(e){
        
        const t = e.target;
        const v = t.type === 'checkbox' ? t.checked : t.value;
        const n = t.name;
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[n] = v;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
         
    }

    /**
     * funkce ukládá data (jazykove mutace), která byla vložena do formuláře přes tinyMCE editor
     * @param {String} data data tinyMCE editoru
     * @param {String} paramName nazev parametru, kteremu nastavujeme textovou hodnotu
     */
    
    formLangHandleEditor(content,paramName){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang[paramName] = content;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Vraci hodnotu zadaneho parametru z jazykovych mutaci, podle zadaneho jazyku
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns property value
     */
    getLangValue = (paramName) => {
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                if(lang[paramName] === null){
                    return "";
                }else{
                    return lang[paramName];
                }
            }
        }
        return "";
    }

    /**
     * Vraci počet znaků zadané proměnné v objektu jazykových mutací lang
     * @param {String} paramName - název proměnné v objektu lang
     * 
     * @returns počet znaků
     */
    getCharCount = (paramName) => {        
        for(const lang of this.parent.state.formData.langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                return lang[paramName].length;
            }
        }
        return 0;
    }

    

    /**
     * Nastaví datum publikování
     * @param {Date} date - instance datumu datepickeru
     * 
     */
    setPublishDate(date){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                lang.publishDate = date;
            }
        }

        this.parent.setState({...this.parent.state,formData:{...this.parent.state.formData,langs: langs}});
    }

    /**
     * Zjisti, jestli byl již daný článek publikován
     * @param {Date} date - instance datumu datepickeru
     * 
     */
    articlePublished(date){
        
        let langs       = this.parent.state.formData.langs;
        for(const lang of langs){
            if(lang.lang == this.parent.state.formData.selectedLang){
                if(lang.published){
                    return true;
                }else{
                    return false;
                }
            }
        }
        return false;
    }






    updateAfterAddArticleForm(cache, response,articleID){

        const { articleForms } = cache.readQuery({ query: GET_ARTICLE_FORMS,variables:{articleID,lang:"cz"} });

        cache.writeQuery({ 
            query:GET_ARTICLE_FORMS,
            variables:{articleID,lang:"cz"},
            data:{
                articleForms: [response.data.addArticleForm,...articleForms]
            } 
        });

        
    }

    updateAfterDeleteArticleForm(cache, response,articleID){

        var resp = response.data.deleteArticleForms.split(",");

        const { articleForms } = cache.readQuery({ query: GET_ARTICLE_FORMS,variables:{articleID,lang:"cz"} });
        var arr = [...articleForms];

        resp.forEach((it,ind) => {
            arr.forEach((item,index) => {
                if(item.articleFormID == it){
                    arr = [...arr.slice(0,index),...arr.slice(index + 1)]
                }
            });
        })
    
        cache.writeQuery({ 
            query:GET_ARTICLE_FORMS,
            variables:{articleID,lang:"cz"},
            data:{
                articleForms: [...arr]
            } 
        });

    }

    
}

export default Article;