/**
 * hlavní sekce
 */

const SECTION = {
    backgroundColorClass:"",
    backgroundImage:"",
    sectionName:{},
    fullWidth:0,
    fullHeight:0,
    paddingClass:"padding",
    showAddElements:false,
    elements:[],
    className:""
}

/**
 * Seznam veškerých elementů
 */

const ELEMENTS_LIST = ["slider","videoSlideShow","review","alert","drag_columns","service","form","faq","columns","headline","paragraph","image","button","video","articles","newsletter","html"];

/**
 * definice elementu sloupce
 */

const COLUMNS = {
    columns:{
        showMenu:true,
        className:"",
        xlCols:"",
        lgCols:"",
        mdCols:"",
        smCols:"",
        xsCols:"",
        cols:[]
    }
}

/**
 * definice elementu sloupce
 */

const COLUMN = {
    showAddElements:false,
    showSettings:false,
    showMenu:true,
    contentAlign:"start",
    paddingTop:0,
    paddingRight:0,
    paddingBottom:0,
    paddingLeft:0,
    elements:[],
    className:""
}

/**
 * definice elementu seřazeného sloupce
 */

const DRAG_COLUMN = {
    showAddElements:false,
    showSettings:false,
    showMenu:false,
    contentAlign:"start",
    paddingTop:0,
    paddingRight:0,
    paddingBottom:0,
    paddingLeft:0,
    xlCols:"25",
    lgCols:"25",
    mdCols:"25",
    smCols:"25",
    xsCols:"25",
    elements:[],
    className:""
}

/**
 * definice elementu kontejneru pro seřazeného sloupce
 */

const DRAG_COLUMNS = {
    drag_columns:{
        showMenu:true,
        className:"",
        cols:[DRAG_COLUMN]
    }
}

/**
 * Definice elementu nadpis
 */

const HEADLINE = (langs) => { 

    var text = {};
    var subHeadlineText = {};

    for(let i in langs){
        text[langs[i].suffix] = "Nadpis sekce " + langs[i].suffix;
        subHeadlineText[langs[i].suffix] = "Podnadpis dané sekce s trošku delším textem " + langs[i].suffix;
    }

    return { 
        headline: {
            showMenu:true,
            showSettings:false,
            type:"h1",
            text:text,
            textAlign:"text-center",
            hasSubHeadline:1,
            subHeadlineText:subHeadlineText,
            className:""
        }
    }
}

const PARAGRAPH = (langs) => { 

    var text = {};

    for(let i in langs){
        text[langs[i].suffix] = "Text daného odstavce " + langs[i].suffix;
    }

    return { 
        paragraph: {
            showMenu:true,
            showSettings:false,
            showLinkSettings:false,
            text:text,
            textAlign:"text-left",
            fontSize:"small",
            className:""
        }
    }
}

const IMAGE = { 
    image: {
        showMenu:true,
        showSettings:false,
        url:"",
        file:"",
        imageAlign:"center",
        imageQuality:"stredni",
        width:300,
        height:300,
        useDimensions:0,
        borderRadius:0,
        className:""
    }
}

const BUTTON = (langs) => { 

    var text = {};

    for(let i in langs){
        text[langs[i].suffix] = "Název tlačítka " + langs[i].suffix;
    }

    return {
        button: {
            showMenu:true,
            showSettings:false,
            text:text,
            buttonAlign:"center",
            dividingLine:"0",
            url:"",
            color:"",
            size:"",
            className:"",
            dividingLineClass:""
        }
    }
}

const VIDEO = (langs) => { 

    var url = {};
    var type = {};
    var mp4FileUrl = {};
    var mp4FilePosterUrl = {};
    var mp4File = {};
    var mp4FilePoster = {};

    for(let i in langs){
        url[langs[i].suffix] = "";
        type[langs[i].suffix] = "youtube";
        mp4File[langs[i].suffix] = "";
        mp4FileUrl[langs[i].suffix] = "";
        mp4FilePoster[langs[i].suffix] = "";
        mp4FilePosterUrl[langs[i].suffix] = "";
    }

    return {
        video: {
            showMenu:true,
            showSettings:false,
            url:url,
            type:type,
            mp4File,
            mp4FileUrl,
            mp4FilePoster,
            mp4FilePosterUrl,
            mp4Align:"center",
            mp4Width:300,
            mp4Height:300,
            useDimensions:0,
            borderRadius:0,
            changeVideo:false,
            className:""
        }
    }
}

const ARTICLES = { 
    articles: {
        showMenu:true,
        showSettings:false,
        count:4,
        className:""
    }
}

const NEWSLETTER = { 
    newsletter: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

const HTML_ELM = (langs) => { 

    var code = {};

    for(let i in langs){
        code[langs[i].suffix] = "";
    }

    return {
        html: {
            showMenu:true,
            showSettings:false,
            code:code,
            type:"html",
            className:""
        }
    }
}

const FAQ_ELM_QUESTION = (langs) => { 

    var text  = {};
    var title = {};

    for(let i in langs){
        text[langs[i].suffix]  = "Text pro odpověď " + langs[i].suffix;
        title[langs[i].suffix] = "Otázka " + langs[i].suffix;
    }

    return { 
        text:text,
        title:title,  
    }
}

const FAQ_ELM = (langs) => { 

    var q = [];
    q.push(FAQ_ELM_QUESTION(langs));

    return {
        faq: {
            showMenu:true,
            showSettings:false,
            className:"",
            questions:q
        }
    }
}

const VIDEO_SLIDE_SHOW_ITEM = (langs) => { 

    var url  = {};
   
    for(let i in langs){
        url[langs[i].suffix]  = "";
    }

    return { 
        url:url,
        orientation:"portrait" 
    }
}

const VIDEO_SLIDE_SHOW = (langs) => { 

    var items = [];
    items.push(VIDEO_SLIDE_SHOW_ITEM(langs));

    var leftMainVideo = {};
    var rightMainVideo = {};

    for(let i in langs){
        leftMainVideo[langs[i].suffix] = "";
        rightMainVideo[langs[i].suffix] = "";
    }

    return {
        videoSlideShow: {
            showMenu:true,
            showSettings:false,
            className:"",
            leftMainVideo:leftMainVideo,
            rightMainVideo:rightMainVideo,
            items:items
        }
    }
}


const FORM_ELM = (langs) => { 

    var text = {};

    for(let i in langs){
        text[langs[i].suffix] = "Odeslat " + langs[i].suffix;
    }

    return {
        form: {
            showMenu:true,
            showSettings:false,
            className:"",
            formID:0,
            color:"",
            size:"",
            text
        }
    }
    
}

const SERVICE_ELM = (langs) => { 

    return {
        service: {
            showMenu:true,
            showSettings:false,
            className:"",
            serviceID:0,
            color:""
        }
    }
    
}

const REVIEW_ELM = { 

    review: {
        showMenu:true,
        showSettings:false,
        withoutQuotationMarks:"0",
        className:"",
        reviewIDs:[],
    }
    
}

const ALERT = (langs) => { 

    var text = {};
   
    for(let i in langs){
        text[langs[i].suffix] = "Vše broběhlo parádně " + langs[i].suffix;
    }

    return {
        alert: {
            showMenu:true,
            showSettings:false,
            text:text,
            type:"success",
            className:""
        }
    }
}

const SLIDER = { 
    slider: {
        showMenu:true,
        showSettings:false,
        className:""
    }
}

export {
    SECTION,
    COLUMNS,
    COLUMN,
    HEADLINE,
    ELEMENTS_LIST,
    PARAGRAPH,
    IMAGE,
    BUTTON,
    VIDEO,
    ARTICLES,
    NEWSLETTER,
    HTML_ELM, 
    FAQ_ELM, 
    FAQ_ELM_QUESTION,
    FORM_ELM,
    SERVICE_ELM,
    DRAG_COLUMNS,
    DRAG_COLUMN,
    ALERT,
    REVIEW_ELM,
    VIDEO_SLIDE_SHOW,
    VIDEO_SLIDE_SHOW_ITEM,
    SLIDER
};