/*
    Výběr veškerých kategorií
*/
import React,{ Component } from 'react';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import NoItems from '../../../GlobalComponents/NoItems';
import {UPDATE_VIDEO_PRIORITY,GET_VIDEOS,DELETE_VIDEOS} from '../Queries/index.js';
import AddEditVideo from './AddEditVideo';
import Helper from '../../../Library/helper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import edit from '../../../Public/Images/edit.svg';
import move from '../../../Public/Images/move.svg';
import Notification from '../../../Library/notification';
import { withApollo } from 'react-apollo';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

const INITIAL_STATE = {
    showDeleteCategoryNotifi : false,
    showDoNotDelete:false,
    showAddCategory:false,
    selectedVideoID:0,
    videoID:0,
    deleteCat:null,
    selectedItems:[],
    showAddCategory : false,
    showDeleteNotifi:false
}

class allVideos extends Component{

    static contextType = AuthContext;

    constructor(props){
        super(props);
        this.state = INITIAL_STATE;

        this.authLib = new Auth();
    }


    delCategory = (action) => {
        if(action){
            let videoIDs = this.state.selectedItems.join(",");
            this.state.deleteVideos({
                variables:{
                    videoIDs
                }
            });
            
            this.setState(INITIAL_STATE);
        }else{
            this.setState({
                showDeleteNotifi:false
            });
        }
    }

    showDoNotDel = (action) => {
        this.setState({
            showDoNotDelete:action
        });
    }

    openCloseModal = (type,videoID) => {
        this.setState({
            showAddCategory:type,
            selectedVideoID:videoID
        })
    }
    
    /**
     * smazání kategorie
     * @param {*} deleteVideos funkce, která posílá data na server
     * @param {*} videoID ID kategorie
     */
    
    checkItemToDelete = (e,deleteVideos,videoID) => {

        var checked = e.target.checked;
        var arr = [...this.state.selectedItems];

        if(!checked){
            for(let i in this.state.selectedItems){
                if(this.state.selectedItems[i] == videoID){
                    arr.splice(i,1);
                }
            }
        }else{
            arr = [videoID,...arr];
        }
        
        this.setState({
            deleteVideos,
            selectedItems:arr
        });
  
    }
    
    openCloseAddCategoryModal = (type) => {
        this.setState({
            showAddCategory:type,
        })
    }
    
    showDelNotifi = () => {

        if(this.state.selectedItems.length > 0){
            this.setState({
                showDeleteNotifi:true
            });
        }else{
            let notify = new Notification();
            notify.setNotification(null,'Nevybrali jste žádnou položku.',false,true,this.props.client);
        }
    }
    onDragEnd = (result,updateCategoryPriority) => {

        const IDs = result.draggableId.split("-");

        updateCategoryPriority({
            variables:{
                videoID: IDs[0],
                fromIndex:result.source.index,
                toIndex:  result.destination.index
            }
        });

    }

    render(){
        
        const {selectedItems} = this.state;

        const adminRules = this.authLib.GetObjFromRules(this.context.rules);
        if(!adminRules.showVideos)
            window.location.href="/";

        return(
            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        <div className="card main">
                            
                            <div className="card-header d-flex align-items-center">
                                Seznam videí
                                <button className="btn btn-primary btn-thiner ml-auto" onClick={() => this.openCloseModal(true)}>Přidat</button>
                                <button className="btn btn-danger btn-thiner ml-2" onClick={() => this.showDelNotifi()}>Smazat označené</button>
                            </div>
                            <div className="card-body">
                                <Query query={GET_VIDEOS} errorPolicy="all" variables={{ lang:"cz" }}>
                                    {
                                        ({data,loading,error,refetch }) => {
                                            if(error){
                                                const helper = new Helper(); 
                                                return (<Error text={helper.getApolloErrorText(error)} />);
                                            }
                                            if(loading) return (<Loading />);
                                            
                                            return(
                                                <div>
                                                    <div className="data-list">
                                                        
                                                            <div className="data-list-item header">
                                                                <div className="data-list-item-content">
                                                                    <div>Název videa</div>
                                                                    <div className="text-center">Kategorie videa</div>
                                                                    <div className="text-right">Možnosti</div>
                                                                </div>
                                                            </div>

                                                            <Mutation
                                                                mutation = {DELETE_VIDEOS}
                                                                update = {async (cache, response) => {
                                                                    
                                                                    let notify = new Notification();
                                                                    notify.setNotification(cache,'Úspěšně smazáno',true,false);
                                                
                                                                }}
                                                                refetchQueries = {(data)=>{
                                                                    return [{
                                                                        query:GET_VIDEOS,
                                                                        variables: { lang:"cz" }
                                                                    }]
                                                                }}
                                                                onError = {(error) => {
                                                                    const helper = new Helper(); 
                                                                    let notify = new Notification();
                                                                    notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                                                }}
                                                            >
                                                                {
                                                                    (deleteVideos,response) => {
                                                                
                                                                        return(
                                                                            <Mutation 
                                                                                mutation={UPDATE_VIDEO_PRIORITY} 
                                                                                update={() => {
                                                                                    refetch();
                                                                                }}
                                                                            >
                                                                            {
                                                                                (updateCategoryPriority,{error}) => {

                                                                                    if(error){
                                                                                        const helper = new Helper(); 
                                                                                        return (<Error text={helper.getApolloErrorText(error)} />);
                                                                                    }

                                                                                    return (
                                                                                        <DragDropContext onDragEnd={(result) => this.onDragEnd(result,updateCategoryPriority)}>
                                                                                            <Droppable droppableId="droppable">
                                                                                                {(provided, snapshot) => (
                                                                                                    <div
                                                                                                        {...provided.droppableProps}
                                                                                                        ref={provided.innerRef}
                                                                                                    >
                                                                                                        {data.allVideos && data.allVideos.map((item,index) => {
                                                                                                            
                                                                                                            if(item.videoID){
                                                                                                                
                                                                                                                var checked = false;
                                                                                                                for(let i in selectedItems){
                                                                                                                    if(selectedItems[i] == item.videoID)checked = true;
                                                                                                                }

                                                                                                                return (
                                                                                                                    <Draggable key={index} draggableId={item.videoID} index={index}>
                                                                                                                        {(provided, snapshot) => (
                                                                                                                        <div className="data-list-item" 
                                                                                                                            ref={provided.innerRef}
                                                                                                                            {...provided.draggableProps}
                                                                                                                            {...provided.dragHandleProps}
                                                                                                                        >
                                                                                                                            <div className="data-list-item-content categories">
                                                                                                                                <div className="cross" style={{paddingLeft:10}}>
                                                                                                                                    <img className="move-icon" src={move} />
                                                                                                                                    {item.name}
                                                                                                                                </div>
                                                                                                                                <div className="text-center">{item.category.name}</div>
                                                                                                                                <div className="text-right">
                                                                                                                                    <img onClick={() => this.openCloseModal(true,item.videoID)} className="edit-icon" src={edit} /> 
                                                                                                                                    <input className="delete-checked" type="checkbox" name="videosToDelete[]" checked={checked} onChange = {(e) => this.checkItemToDelete(e,deleteVideos,item.videoID)} />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        )}
                                                                                                                    </Draggable>
                                                                                                                )
                                                                                                            }else{
                                                                                                                return "";
                                                                                                            }
                                                                                                        })}
                                                                                                        {provided.placeholder}
                                                                                                    </div>
                                                                                                )}
                                                                                            </Droppable>
                                                                                        </DragDropContext>
                                                                                    )
                                                                            }}
                                                                            </Mutation>
                                                                        )
                                                                    }
                                                                }
                                                            </Mutation>
                                                        
                                                    </div>
                                                    {data.allVideos && data.allVideos.length == 0 &&
                                                        <NoItems text={"Momentálně se zde nenachází žádné položky."} />
                                                    }
                                                    {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat vybrané položky ?'} callback={this.delCategory} /> }
                                                    {this.state.showAddCategory &&
                                                        <AddEditVideo openCloseModal={this.openCloseModal} selectedVideoID={this.state.selectedVideoID} />
                                                    }
                                                </div>
                                            );
                                        }
                                    }
                                </Query>
                            </div>
                        </div>
                    </div>
                </div> 			
            </div>
        
        );

    }

}


export default withApollo(allVideos);