/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component,Fragment } from 'react';
import { withApollo } from 'react-apollo';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_INVOICES,ADD_INVOICE_CREDIT_NOTE} from '../Queries/invoice.js';
import notes from '../../../Public/Images/notes.svg';
import notesD from '../../../Public/Images/notes_D.svg';
import Helper from '../../../Library/helper';
import {SERVER_URL} from '../../../Config/index';
import moment from 'moment';
import DatePicker,{ registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import cs from 'date-fns/locale/cs';
import InvoiceLib from '../Library/invoice';
import Modal from 'react-modal';
import remove from '../../../Public/Images/remove.svg';
import Notification from '../../../Library/notification';
import edit from '../../../Public/Images/edit.svg';
import AddEditInvoice from './AddEditInvoice.js';
import Auth from '../../Auth/Library/auth.js';
import { AuthContext } from '../../Auth/Components/AuthProvider.js';

registerLocale('cs', cs);

const INITIAL_STATE = {
    
    showExport:false,
    showAddCreditNote:false,
    showAddEditInvoice:false,
    loadingExport:true,
    progresPercentage:0,
    invoiceCount:0,
    completedCount:0,
    selectedInvoiceID:0,
    zipName:"",
    creditNoteDate:moment().toDate(),
    creditNoteReason:"Refund",
    invoiceListVariables:{
        limit:50,
        offset:0,
        lang:"cz",
        dateFrom: moment().subtract(2,'months').startOf("day").toDate(),
        dateTo:moment().endOf("day").toDate(),
        selectedDomains:[],
        selectedServices:[],
        //dateFrom: moment().subtract(1,'months').format("YYYY-MM-DD 00:00:00"),
        //dateTo:moment().format("YYYY-MM-DD 23:59:59"),
    }
}

class Invoice extends Component{

    static contextType = AuthContext;

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;

       this.ShowAddEditInvoice = this.ShowAddEditInvoice.bind(this);
       
       this.authLib = new Auth();
      
    }

    componentDidMount(){
        this.invoiceLib = new InvoiceLib(this);
    }

    closeExport(){
        this.setState({showExport:false,loadingExport:true});
    }

    showAddCreditNote(invoiceID){
        this.setState({showAddCreditNote:true,selectedInvoiceID:invoiceID});
    }

    ShowAddEditInvoice(value,invoiceID){
        this.setState({showAddEditInvoice:value,selectedInvoiceID:invoiceID});
    }

    closeAddCreditNote(){
        this.setState({showAddCreditNote:false,selectedInvoiceID:0});
    }

    render(){

        const adminRules = this.authLib.GetObjFromRules(this.context.rules);
        if(!adminRules.showInvoices)
            window.location.href="/";


        const {selectedInvoiceID,invoiceListVariables,showExport,progresPercentage,invoiceCount,completedCount,loadingExport,zipName,showAddCreditNote,showAddEditInvoice} = this.state;

        return(

            <div id="settings" className="whole-container" >
            	
                <div className="row" >
                    <div className="col-sm one">
                        
                        <Query 
                            query={GET_INVOICES}
                            variables = {{
                                limit:invoiceListVariables.limit,
                                offset:invoiceListVariables.offset,
                                lang:invoiceListVariables.lang,
                                dateFrom: moment(invoiceListVariables.dateFrom).format("YYYY-MM-DD 00:00:00"),
                                dateTo:moment(invoiceListVariables.dateTo).format("YYYY-MM-DD 23:59:59"),
                                selectedDomains:invoiceListVariables.selectedDomains,
                                selectedServices:invoiceListVariables.selectedServices
                            }}
                            fetchPolicy = {'network-only'}
                        >
                            {({data,loading,error,fetchMore,refetch  }) => {
                                
                                if(loading) return (<Loading />);
                                if(error){
                                    const helper = new Helper(); 
                                    return (<Error text={helper.getApolloErrorText(error)} />);
                                }

                                return(
                                    <div className="card main">
                                        <div className="card-header">
                                            
                                            <div>
                                                <div>
                                                    <strong>Od: </strong>
                                                    <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={invoiceListVariables.dateFrom} onChange={date => this.invoiceLib.setDateFrom(date)} />
                                                    <strong> Do: </strong>
                                                    <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={invoiceListVariables.dateTo} onChange={date => this.invoiceLib.setDateTo(date)} />
                                                    <button onClick={() => this.invoiceLib.invoiceExport()} className="btn btn-primary ml-2">Export faktur</button>
                                                    <button onClick={() => this.invoiceLib.invoiceExportToCSV()} className="btn btn-primary ml-2">Export do excelu</button>
                                                    <button onClick={() => this.ShowAddEditInvoice(true,0)} className="btn btn-primary ml-2">Přidat fakturu</button>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    
                                                    {data.allDomains && data.allDomains.map((item,index) => {
                                                        
                                                        var checked = false;
                                                        for(let i in invoiceListVariables.selectedDomains){
                                                            if(invoiceListVariables.selectedDomains[i] == item.domainID){
                                                                checked = true;
                                                                break;
                                                            }

                                                        }

                                                        return (
                                                            <div className={(index > 0 ? "form-check ml-2" : "form-check")} key={index}>
                                                                <input className="form-check-input" type="checkbox" checked={checked} value = {item.domainID} id={"domain" + index} onChange={(e) => this.invoiceLib.setDomain(e)} />
                                                                <label className="form-check-label" htmlFor={"domain" + index} >
                                                                    {item.domainName}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    
                                                    {data.allServices && data.allServices.map((item,index) => {

                                                        var checked = false;
                                                        for(let i in invoiceListVariables.selectedServices){
                                                            if(invoiceListVariables.selectedServices[i] == item.serviceID){
                                                                checked = true;
                                                                break;
                                                            }

                                                        }
                                                        
                                                        return (
                                                            <div className={(index > 0 ? "form-check ml-2" : "form-check")}  key={index}>
                                                                <input className="form-check-input" type="checkbox" id={"service" + index} checked={checked} value = {item.serviceID} onChange={(e) => this.invoiceLib.setService(e)} />
                                                                <label className="form-check-label" htmlFor={"service" + index} >
                                                                    {item.name}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                
                                            </div>
                                            
                                        </div>
                                        <div className="card-body">

                                            <ul className="text-right" style={{listStyleType:"none"}}>
                                                
                                                <li><strong>Počet:</strong> {data.totalInvoices} </li>
                                                <li>
                                                    <strong>Celkem:</strong> {data.totalInvoicesPrice} | <strong>70%:</strong> {Math.round((data.totalInvoicesPrice / 100) * 70 * 100) / 100} | <strong>30%:</strong> {Math.round((data.totalInvoicesPrice / 100) * 30 * 100) / 100} 
                                                </li>
                                            </ul>
                            
                                            <div>
                                                <div className="data-list">
                                                    
                                                    <div className="data-list-item header">
                                                        <div className="data-list-item-content">
                                                            <div className="static">Číslo faktury</div>
                                                            <div className="text-center static">Jméno</div>
                                                            <div className="text-center static">Příjmení</div>
                                                            <div className="text-center">Email</div>
                                                            <div className="text-center static">Tel</div>
                                                            <div className="text-center static">Datum vložení</div>
                                                            <div className="text-center static">Datum splatnosti</div>
                                                            <div className="text-center static">Celková částka</div>
                                                            <div className="text-right static">Možnosti</div>
                                                        </div>
                                                    </div>
                                                    
                                                    {data.allFilteredInvoices && data.allFilteredInvoices.map((item,index) => {

                                                                                                                                                                                
                                                        return (
                                                        
                                                            <div key={index} className="data-list-item-content">
                                                                <div className="static">{item.invoiceNumber}</div>
                                                                <div className="text-center static">{item.name}</div>
                                                                <div className="text-center static">{item.surname}</div>
                                                                <div className="text-center">{item.email}</div>
                                                                <div className="text-center static">{item.tel}</div>
                                                                <div className="text-center static">{item.dateAddStr}</div>
                                                                <div className="text-center static">{item.dueDateStr}</div>
                                                                <div className="text-center static">{item.totalPrice} {item.currency}</div>
                                                                <div className="text-right static">
                                                                    
                                                                    <img onClick={() => this.ShowAddEditInvoice(true,item.invoiceID)} className="remove-icon" src={edit} />
                                                                    <a target="_blank" href={SERVER_URL + "/generate/invoice/" + item.invoiceID}><img title="tisk faktury" className="print-icon ml-2" src={notes} /></a>  
                                                                    {!item.invoiceCreditNote ?
                                                                        <img onClick={() => this.showAddCreditNote(item.invoiceID)} className="remove-icon ml-2" src={remove} />
                                                                    :
                                                                        <a className="ml-2" target="_blank" href={SERVER_URL + "/generate/invoice-credit-note/" + item.invoiceCreditNote.invoiceCreditNoteID}><img title="tisk dobropisu" className="print-icon" src={notesD} /></a>    
                                                                    }
                                                                </div>
                                                            </div>
                                                                    
                                                        )
                                                        
                                                    })} 
 
                                                </div>
                                                
                                                {data.allFilteredInvoices && data.allFilteredInvoices.length == 0 ?
                                                    <NoItems text={"V tomto období se momentálně nenachází žádné faktury."} />
                                                :
                                                    <div className="text-center p-5">
                                                        <button className="btn btn-primary" onClick={() => fetchMore({
                                                            variables: {
                                                                offset: data.allFilteredInvoices.length
                                                            },
                                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                                if (!fetchMoreResult) return prev;
                                                                return Object.assign({}, prev, {
                                                                    allFilteredInvoices: [...prev.allFilteredInvoices, ...fetchMoreResult.allFilteredInvoices]
                                                                });
                                                            }
                                                        })}> Načíst další faktury </button>
                                                    </div> 

                                                }
                                               
                                            </div>
                                        </div>
                                        {showAddEditInvoice ?
                                            <AddEditInvoice
                                                refetchInvoices = {refetch}
                                                selectedInvoiceID = {selectedInvoiceID}
                                                ShowAddEditInvoice = {this.ShowAddEditInvoice}
                                            />
                                        :null}
                                    </div>
                                    );
                                }
                            }
                        </Query>

                    </div>
                </div> 	

                <Modal
                    isOpen={showExport}
                    className="Modal__Bootstrap modal-dialog modal-lg"
                    closeTimeoutMS={150}
                    onRequestClose={() => this.closeExport()}
                >

                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Export faktur</h4>
                        </div>
                        <div className="modal-body">
                            {loadingExport ? 
                                <Fragment>
                                    <Loading />
                                    <div className="progress" style={{height:"30px"}}>
                                        <div className="progress-bar" role="progressbar" style={{width:Math.round((completedCount / invoiceCount * 100 )) + "%"}} aria-valuenow={Math.round((completedCount / invoiceCount * 100 ))} aria-valuemin="0" aria-valuemax="100">{completedCount}/{invoiceCount}</div>
                                    </div>
                                </Fragment>
                                :
                                <div className="text-center pt-5 pb-5"><a style={{fontSize:"30px"}} href={SERVER_URL + "/Public/Invoices/Export/" + zipName}>STÁHNOUT FAKTURY</a></div>
                            }
                        </div>
                    </div>


                </Modal>

                <Modal
                    isOpen={showAddCreditNote}
                    className="Modal__Bootstrap modal-dialog"
                    closeTimeoutMS={150}
                    onRequestClose={() => this.closeAddCreditNote()}
                >

                    <Mutation 
                        mutation={ADD_INVOICE_CREDIT_NOTE}
                        update = {async (cache, response) => {

                            this.invoiceLib.updateList(cache,response,selectedInvoiceID,invoiceListVariables);
                            
                            let notify = new Notification();
                            notify.setNotification(cache,'Úspěšně vytvořeno',true,false);
                            

                        }}
                        onCompleted = {(response) => {

                            this.closeAddCreditNote()
                             
                        }}
                        
                    >
                        {
                            (addInvoiceCreditNote,{data,loading,error}) => {

                                if(error){
                                    const helper = new Helper(); 
                                    error = helper.getApolloErrorText(error);
                                }
                                
                                return(

                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Upozornění</h4>
                                            <button type="button" className="close" onClick={() => this.closeAddCreditNote()}>
                                            <span aria-hidden="true">x</span>
                                            <span className="sr-only">Close</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">

                                            {error ?
                                                <Error text={error} />
                                            :
                                            <>
                                                <p>Opravdu chcete vytvořit dopropis k této faktuře ?</p>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="input-label d-block">Datum dobropisu</label>
                                                            <DatePicker className="form-control" dateFormat="dd.MM.yyyy" locale="cs" selected={this.state.creditNoteDate} onChange={date => this.setState({creditNoteDate:date})} />
                                                        </div>  
                                                    </div>  
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="input-label d-block">Důvod vytvoření dobropisu</label>
                                                            <input className="form-control" type="text" name="reason" value={this.state.creditNoteReason} onChange={(e) => this.setState({creditNoteReason:e.target.value})} />
                                                        </div>  
                                                    </div>
                                                </div> 
                                            </>
                                            }
                                            
                                        </div>
                                        <div className="modal-footer"> 
                                            <button type="button" className="btn btn-primary flex-fill" onClick={() => this.invoiceLib.addInvoiceCreditNote(addInvoiceCreditNote,selectedInvoiceID,this.state.creditNoteDate,this.state.creditNoteReason)}>ANO</button>
                                            <button type="button" className="btn btn-danger" onClick={() => this.closeAddCreditNote()}>Ne</button>
                                        </div>
                                    </div>
                                )

                            }
                        }

                    </Mutation>

                </Modal>
                
            </div>
        );

    }

}

export default withApollo(Invoice);