/**
 * soubor se seznamem veškerých graphql dotazů pro zákazníka
 */
import gql from 'graphql-tag';

/**
 * vložení a editace zákazníka
 */
export const ADD_EDIT_CUSTOMER = gql`
    mutation AddEditCustomer(
        $customerID:ID,
        $name: String,
        $surname: String,
        $company: String,
        $email: String,
        $tel: String,
        $street: String,
        $city: String,
        $zip: String,
        $countryID:ID,
        $domainID:ID,
        $autoPayments:Int,
        $membershipDuration:ID,
        $description: String,
        $yearOfBirth: Int
    ){
        addEditCustomer(
            customerID:          $customerID,
            name:                $name,
            surname:             $surname,
            company:             $company,
            email:               $email,
            tel:                 $tel,
            street:              $street,
            city:                $city,
            zip:                 $zip,
            countryID:           $countryID,
            domainID:            $domainID,
            description:         $description,
            autoPayments:        $autoPayments,
            membershipDuration:  $membershipDuration,
            yearOfBirth:         $yearOfBirth
        ){
            customerID
            name
            surname
            company
            email
            tel 
            membershipTo
            membershipDuration
            autoPayments
            yearOfBirth
            membership{
                name
            }        
        }
    }
`;

export const GET_CUSTOMERS_CREDIT_CARDS = gql`
query AllCustomerCreditCards($email: String!,$customerID:ID!){
    allCustomerCreditCards(email:$email,customerID:$customerID){
        name
        token
    }
}
`;

export const DELETE_CUSTOMERS_CREDIT_CARD = gql`
    mutation DeleteCustomersCreditCard($token: String!,$customerID:ID!){
        deleteCustomersCreditCard(token:$token,customerID:$customerID)
    }

`;

/**
 *  Data pro jednoho zákazníka
 */

export const GET_CUSTOMER_BY_EMAIL = gql`
    query CustomerByEmail($email: String!,$customerID:ID!){
        customerByEmail(email:$email,customerID:$customerID){
            customerID
        }
    }
`;

/**
 *  Data pro jednoho zákazníka
 */

export const GET_CUSTOMER = gql`
    query Customer($customerID: ID!,$lang: String!){
        oneCustomer(customerID:$customerID){
            customerID
            name
            surname
            company
            email
            tel
            street
            city
            zip
            countryID
            domainID
            description
            autoPayments
            membershipDuration
            yearOfBirth
        }
        allCountries{
            countryID
            name
            defaultSelected
        }
        allAdminMembershipDurations(lang:$lang){
            membershipDurationID
            name
            value
        }
        allDomains{
            domainID
            domainName
        }
        
    }
`;

/**
 *  Seznam veškerých zákazníků
 */
export const GET_CUSTOMERS = gql`
    query AllCustomers($offset:Int, $limit: Int,$text:String){
        allCustomers(offset:$offset,limit:$limit,text:$text){
            customerID
            customerStripeID
            countryID
            name
            surname
            company
            email
            tel 
            street
            city
            zip
            ic
            dic
            membershipTo
            membershipDuration
            autoPayments
            yearOfBirth
            membership{
                name
                length
            }
        }
        
    }
`;

/**
 * Smazání zákazníka.
 */
export const DELETE_CUSTOMER = gql`
    mutation deleteCustomers($customerID:ID!) {
        deleteCustomers(customerID:$customerID)
    }
`;

/**
 *  seznam veškerých faktur zákazníka
 */

export const GET_CUSTOMERS_INVOICES = gql`
    query CustomerInvoices($offset:Int, $limit: Int,$customerID:ID){
        allCustomerInvoices(offset:$offset,limit:$limit,customerID:$customerID){
            invoiceID
            invoiceNumber
            dateAddStr
            totalPrice
            dueDateStr
            items{
                invoiceItemID
                text
                count
                amount
            }
        } 
    }
`;

export const REMOVE_MEMBERSHIP = gql`
    mutation RemoveMembership(
        $customerStripeID:ID
    ){
        removeMembership(
            customerStripeID:  $customerStripeID
        ){
            customerStripeID
            autoPayments
        }
    }
`;
