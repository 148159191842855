/**
 *  komponenta pro štítky pro produkty
 */
import React,{ Component,Fragment } from 'react';
import { withApollo } from 'react-apollo';
import {Query,Mutation} from 'react-apollo';
import Loading from '../../../GlobalComponents/Loading';
import Error from '../../../GlobalComponents/Error';
import NoItems from '../../../GlobalComponents/NoItems';
import {GET_CUSTOMERS_CREDIT_CARDS, DELETE_CUSTOMERS_CREDIT_CARD} from '../Queries/customer.js';
import Helper from '../../../Library/helper';
import remove from '../../../Public/Images/remove.svg';
import ModalNotification from '../../../GlobalComponents/ModalNotification';
import Notification from '../../../Library/notification';
import CreditCardLib from '../Library/creditCardLib';

const INITIAL_STATE = {
    customerListVariables:{
        limit:50,
        offset:0,
    },
    showDeleteNotifi:false,
    deleteCreditCards:"",
    token:""
    
}

class CreditCards extends Component{

    constructor(props){
       super(props);
       this.state = INITIAL_STATE;

       this.delCreditCard = this.delCreditCard.bind(this);
    }



    deleteCreditCard(deleteCreditCards,token){
        
        this.setState({
            deleteCreditCards,
            token,
            showDeleteNotifi:true,
        });
  
    }

    delCreditCard(action){

        if(action){

            this.state.deleteCreditCards({
                variables:{
                    customerID: this.props.customerID,
                    token: this.state.token
                }
            })
        }
        this.setState({
            showDeleteNotifi:false,
            deleteCreditCards:"",
            token:""
        });
    }


    render(){

        const {customerID,email} = this.props;

        return(
         
            <Query 
                query={GET_CUSTOMERS_CREDIT_CARDS}
                variables = {{
                    customerID,
                    email
                }}
                fetchPolicy = {'network-only'}
            >
                {({data,loading,error,fetchMore }) => {
                    
                    if(loading) return (<Loading />);
                    if(error){
                        const helper = new Helper(); 
                        return (<Error text={helper.getApolloErrorText(error)} />);
                    }

                    return(
                        <div className="card main">
                            <div className="card-header d-flex align-items-center"></div>
                            <div className="card-body">
                
                                <div className="data-list">
                                    
                                    <div className="data-list-item header">
                                        <div className="data-list-item-content">
                                            <div className="">Číslo karty</div>
                                            <div className="text-right static small">Možnosti</div>
                                        </div>
                                    </div>

                                    <Mutation
                                        mutation = {DELETE_CUSTOMERS_CREDIT_CARD}
                                        onError = {(error) => { 
                                            const helper = new Helper(); 
                                            let notify = new Notification();
                                            notify.setNotification(null,helper.getApolloErrorText(error),false,true,this.props.client);
                                        }}

                                        update = {async (cache, response) => {

                                            let creditCardLib = new CreditCardLib(this);
                                            creditCardLib.updateAfterDeleteCustomerCreditCard(cache, response,{customerID,email});

                                            let notify = new Notification();
                                            notify.setNotification(cache,'Úspěšně smazáno',true,false);
                        
                                        }}
                                    >
                                        {
                                            (deleteCustomersCreditCard,{error}) => {
                                                                                                        
                                                return(
                                                    <Fragment>
                                    
                                                        {data.allCustomerCreditCards && data.allCustomerCreditCards.map((item,index) => {
                                                                                                                        
                                                            return (
                                                            
                                                                <div key={index} className="data-list-item-content">
                                                                    <div className="">{item.name}</div>
                                                                                                                    
                                                                    <div className="text-right static small">
                                                                        <img onClick={() => this.deleteCreditCard(deleteCustomersCreditCard,item.token)} className="remove-icon" src={remove} />                                              
                                                                    </div>
                                                                </div>
                                                                        
                                                            )
                                                            
                                                        })} 
                                                                            
                                                        {data.allCustomerCreditCards && data.allCustomerCreditCards.length == 0 ?
                                                            <NoItems text={"Momentálně se zde nenachází žádné kreditní karty."} />
                                                        :
                                                            ""
                                                        }

                                                    </Fragment>
                                                )
                                            }
                                        }

                                    </Mutation>
                                    
                                </div>
                            </div>
                            {this.state.showDeleteNotifi && <ModalNotification yesNo={true} text={'Opravdu chcete vymazat danou kreditní kartu ?'} callback={this.delCreditCard} /> }
                        </div>
                        );
                    }
                }
            </Query>
         
        );

    }

}

export default withApollo(CreditCards);